import React from "react";
import { Loginvalidationschema } from "./schema";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as turf from "@turf/turf";

export const KEY_STRIPE_LIVE =
  "pk_live_51MHZZoEUMHjNjlrqV5T6WIPM55zgcf9Mcgw9svz1mHzDegW0JUmuZilP1aBLtZdnuqPRuXyHweozJTFx6TEMu5B2006W6Uby4S";

export const KEY_STRIPE_DEMO =
  "pk_test_51L5S2aAEo4X1hkOdT3R5XqvOyUHogpktqCwDBW2dWF1mXPgjwRrcNIIbnpKN46mkXCN5523wUlJ1jUOIgAMFdiJY00eNjx5ljG";

export const KEY_STRIPE_LOCAL =
  "pk_test_51MHZZoEUMHjNjlrqJ0SkAVflDjIvaFPPSh8HiVQZGzgWQM9ctFidam587UylVyc11cB2hh6Ga23BQcuPSTJ2eOIm00GY55v4gg";

// export const BASE_URL = "https://rentalsreimagined.com/api-v3/public/api";

export const BASE_URL = "https://rentalsreimagined.com/api-live/public/api";
// export const BASE_URL = "https://test-cmolds.com/rentals-api-v3/public/api"; //Testing

// export function isLocationInLineStringAndNotInPolygon(location, geojson) {
//   let isInLineString = false;
//   let isInPolygon = false;

//   console.warn(location, geojson);

//   // Iterate through the features in the GeoJSON
//   for (let features of geojson) {
//     features.geofencing.features.forEach((feature) => {
//       if (feature.geometry.type === "LineString") {
//         // Check if the point is on the LineString
//         if (
//           turf.booleanPointOnLine(
//             turf.point([location.lng, location.lat]),
//             feature
//           )
//         ) {
//           isInLineString = true;
//         }
//       } else if (feature.geometry.type === "Polygon") {
//         // Check if the point is inside the Polygon
//         if (
//           turf.booleanPointInPolygon(
//             turf.point([location.lng, location.lat]),
//             feature
//           )
//         ) {
//           isInPolygon = true;
//         }
//       }
//     });
//   }

//   //isInLineString &&
//   // Return true only if the point is in LineString and not in any Polygon
//   return isInLineString && !isInPolygon;
// }

export function isLocationInLineStringAndNotInPolygon(location, geojson) {
  let isInLineStringArea = false;
  let isInPolygon = false;

  console.warn(location, geojson);

  // Iterate through the features in the GeoJSON
  for (let features of geojson) {
    features.geofencing.features.forEach((feature) => {
      if (feature.geometry.type === "LineString") {
        // Get the coordinates of the LineString
        const lineStringCoordinates = feature.geometry.coordinates;

        // Ensure the first and last coordinates are the same to form a closed polygon
        if (
          lineStringCoordinates.length >= 3 &&
          (lineStringCoordinates[0][0] !==
            lineStringCoordinates[lineStringCoordinates.length - 1][0] ||
            lineStringCoordinates[0][1] !==
            lineStringCoordinates[lineStringCoordinates.length - 1][1])
        ) {
          lineStringCoordinates.push(lineStringCoordinates[0]);
        }

        // Create a polygon from the LineString coordinates
        const lineStringPolygon = turf.polygon([lineStringCoordinates]);

        // Check if the point is within the polygon formed by the LineString
        if (
          turf.booleanPointInPolygon(
            turf.point([location.lng, location.lat]),
            lineStringPolygon
          )
        ) {
          isInLineStringArea = true;
        }
      } else if (feature.geometry.type === "Polygon") {
        // Check if the point is inside the Polygon
        if (
          turf.booleanPointInPolygon(
            turf.point([location.lng, location.lat]),
            feature
          )
        ) {
          isInPolygon = true;
        }
      }
    });
  }

  // Return true only if the point is in LineString area and not in any Polygon
  return isInLineStringArea && !isInPolygon;
}

export const getIsPointInsidePolygon = (point: number[], vertices) => {
  const x = point.lat;
  const y = point.lng;

  let inside = false;
  for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
    const xi = vertices[i].lat,
      yi = vertices[i].lng;
    const xj = vertices[j].lat,
      yj = vertices[j].lng;

    const intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

export const isPointInPolygon = (point, polygon) => {
  let inside = false;
  const x = point.lat;
  const y = point.lng;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].lat;
    const yi = polygon[i].lng;
    const xj = polygon[j].lat;
    const yj = polygon[j].lng;

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

    if (intersect) {
      inside = !inside;
    }
  }
  return inside;
};

//   export const isPointInPolygon = (point, polygon) => {
//     let isInside = false;
//     let i, j = polygon.length - 1;
//     console.log('====================================');
//     console.log(point);
//     console.log('====================================');
//     console.log('====================================');
//     console.log(polygon);
//     console.log('====================================');
//     for (i = 0; i < polygon.length; i++) {
//       const { lat: x1, lng: y1 } = polygon[i];
//       const { lat: x2, lng: y2 } = polygon[j];
//       const onBoundary = (
//         ((point.lng <= y1 && point.lng >= y2) || (point.lng <= y2 && point.lng >= y1)) &&
//         (point.lat <= x1 || point.lat <= x2) &&
//         (y1 + (point.lat - x1) / (x2 - x1) * (y2 - y1)) <= point.lng
//       );
//       if (onBoundary) return true;
//       if ((x1 < point.lat && x2 >= point.lat) || (x2 < point.lat && x1 >= point.lat)) {
//         if (y1 + (point.lat - x1) / (x2 - x1) * (y2 - y1) < point.lng) {
//           isInside = !isInside;
//         }
//       }
//       j = i;
//     }
//     return isInside;
//   };

export const Images = {
  Step1: require("../assets/Png/Step1.png"),
  Step2: require("../assets/Png/Step2.png"),
  Step3: require("../assets/Png/Step3.png"),
  Step4: require("../assets/Png/Step4.png"),
  Step5: require("../assets/Png/Step5.png"),
  Step6: require("../assets/Png/Step6.png"),
  Step7: require("../assets/Png/Step7.png"),
  Step8: require("../assets/Png/Step8.png"),
  Step9: require("../assets/Png/Step9.png"),
  Step10: require("../assets/Png/Step10.png"),
  Step11: require("../assets/Png/Step11.png"),
};

export function getCardToken(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${KEY_STRIPE_LIVE}`);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const expiry = data.card_expiry_date?.substring(0, 2);
  const expiry2 = data.card_expiry_date?.substring(3, 5);
  const details = {
    "card[name]": data.card_holder_name,
    "card[number]": data.card_number,
    "card[exp_month]": expiry,
    "card[exp_year]": expiry2,
    "card[cvc]": data.card_cvv,
  };

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formUrlEncode(details),
  };

  return fetch(`https://api.stripe.com/v1/tokens`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result.id;
    })
    .catch((err) => console.log(err));
}

export function formUrlEncode(payload) {
  let formBody = [];
  for (var property in payload) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(payload[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }

  return formBody.join("&");
}

export const formatCardNumber = (value) => {
  return value
    .replace(/\s/g, "")
    .replace(/(.{4})/g, "$1 ")
    .trim();
};

export const formatExpiryDate = (value) => {
  const cleanedValue = value.replace(/\D/g, "");
  return cleanedValue.replace(/(.{2})/, "$1/");
};
