import React, { useState, useEffect, useLayoutEffect } from 'react';
import Inputfield from '../Inputfiled';
import HelpSupport from '../../assets/Png/helpsupport.png';
import { get, post } from '../../utils/axiosInstance';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import Spinner from "../Spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from '../Toast';
import { useSelector } from 'react-redux';
import LoadingButton from '../LoadingButton';

const Maincard = () => {
    const user = useSelector((state) => state.user);
    const Token = useSelector((state) => state.token);
    const selectedVendor = useSelector((state) => state.selectedVendor);
    const [loader, setLoader] = useState(false);
    const [vendorData, setVendorData] = useState(null);
    const [loginUser, setLoginUser] = useState(user)
    const [isProcessing, setIsProcessing] = useState(false);

    const fetchVendors = async () => {
        try {
            const res = await get(`/vendor/${user.default_vendor.id}`, {}, {}, Token);
            if ("response" in res) {
                const response = res.response.data.vendor;
                setVendorData(response)
            }
        } catch (err) {
            console.error("Error fetching vendors:", err);
        }
    };

    useEffect(() => {
        fetchVendors();
    }, []);

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email format').required('Email is required'),
        name: Yup.string().required('Name is required'),
        description: Yup.string().required('Description is required'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: "",
            description: '',
        },
        validationSchema,
        onSubmit: (values) => {
            submitForm(values);
        },
    });

    useEffect(() => {
        formik.setValues({
            name: `${vendorData?.title ?? ""}` || "",
            email: vendorData?.support_email || "",
            description: "",
        })
    }, [vendorData]);

    const submitForm = (values) => {
        setIsProcessing(true);
        const Token = localStorage.getItem('token');
        let data = {
            "vendor_id": loginUser?.default_vendor.id,
            "name": values.name,
            "email": values.email,
            "description": values.description
        };
        post(`/vendor/support`, data, {}, Token)
            .then((res) => {
                setIsProcessing(false); // Reset loading state
                Toast({ message: res.response.messages[0], isError: false });
            })
            .catch((err) => {
                setIsProcessing(false); // Reset loading state on error
                Toast({ message: err.response.data.error.messages[0], isError: true });
            });
    };

    if (loader) {
        return (
            <div className="flex justify-center items-center w-full  md:h-[670px] h-[650px]">
                <Spinner className="spinner" isLoading={loader} />
            </div>
        );
    } else {
        return (
            <div className='bg-[#FFFFFF] rounded-[10px] flex flex-col items-center w-[100%] md:h-[670px] h-[87vh]'>
                <div className='border-b-[1px] border-[#EDF0F6] md:py-[20px] py-[9px] w-[90%]'>
                    <p className='md:text-[22px] text-[15px] font-bold text-Textdarkcolor w-[90%]'>LSV Rental Support</p>
                </div>
                <div className='w-[90%] lg:px-2 px-2'>
                    <form className='lg:w-[90%] w-[100%]' onSubmit={formik.handleSubmit}>
                        <img src={HelpSupport} className='w-[492px] h-[240px] mx-auto' alt="Help Support" />
                        <div className='w-[100%] flex flex-col justify-start'>
                            <Inputfield
                                labelstyle="flex md:text-[18px] text-[14px] mt-[18px] text-Textdarkcolor font-regular"
                                inputStyle="mt-[8px] md:text-[18px] text-[14px] bg-[#fff] w-full outline-0 p-3 rounded-[8px] shadow-[0px_8px_32px_#00000014]"
                                Labelname="Name"
                                type="name"
                                placeholder="Enter your name"
                                name="name"
                                id="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                errors={formik.errors.name}
                                touched={formik.touched.name}
                                disabled={true}
                                isEditing={true}
                            />
                            <Inputfield
                                labelstyle="flex md:text-[18px] text-[14px] mt-[18px] text-Textdarkcolor font-regular"
                                inputStyle="mt-[8px] md:text-[18px] text-[14px] bg-[#fff] w-full outline-0 p-3 rounded-[8px] shadow-[0px_8px_32px_#00000014]"
                                Labelname="Email"
                                type="email"
                                placeholder="Enter your Email"
                                name="email"
                                id="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                errors={formik.errors.email}
                                touched={formik.touched.email}
                                disabled={true}
                                isEditing={true}
                            />
                            <div>
                                <label className='flex md:text-[18px] text-[14px] mt-[12px] text-Textdarkcolor font-regular'>Description</label>
                                <textarea
                                    id="description"
                                    rows="3"
                                    className="mt-[8px] md:text-[18px] text-[12px] p-3 w-full bg-[#fff] outline-0 rounded-[8px] shadow-[0px_8px_32px_#00000014]"
                                    placeholder="Write your thoughts here..."
                                    name="description"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description}
                                ></textarea>
                                {formik.touched.description && formik.errors.description ? (
                                    <div className="text-red-500">{formik.errors.description}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className='flex justify-end align-middle mt-2'>
                            <LoadingButton
                                isLoading={isProcessing}
                                type="submit" // Set type to "submit" for the button
                            >
                                Continue
                            </LoadingButton>
                        </div>
                    </form>
                </div>
                <ToastContainer limit={1} />
            </div >
        );
    }
};

export default Maincard;
