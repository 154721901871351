import React, { useState, useEffect } from 'react';
import { get } from '../../utils/axiosInstance';
import Spinner from '../Spinner';
import { useSelector } from 'react-redux';
import Toast from '../Toast';

const WaiverLiability = () => {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const Token = useSelector((state) => state.token);

    const targetName = "Waiver of Liability";
    useEffect(() => {
        get(`/agreement/guest`)
            .then((res) => {
                const response = res?.response?.data?.agreement;
                if (response) {
                    setData(response);
                    console.log('====================================');
                    console.log(response);
                    console.log('====================================');
                } else {
                    Toast({ message: "Something went wrong", isError: true });
                }
            })
            .catch((err) => {
                Toast({
                    message: err?.response?.data?.error?.messages[0],
                    isError: true,
                });
            });
    }, []);
    // useEffect(() => {
    //     setLoader(true);
    //     // const Token = localStorage.getItem("token");
    //     get(`/content/contents`, {}, {}, Token)
    //         .then((res) => {
    //             console.log('====================================');
    //             console.log("res", res);
    //             console.log('====================================');
    //             setLoader(false);
    //             const response = res.response.data.contents;
    //             setData(response || []);
    //         })
    //         .catch((err) => {
    //             setLoader(false);
    //             console.error("Error fetching data:", err);
    //         })
    //         .finally(() => {
    //             setLoader(false);
    //         });
    // }, []);

    // const targetItem = data.find((item) => item.name === targetName);

    if (loader) {
        return (
            <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
                <Spinner className="spinner" isLoading={loader} />

            </div>
        );
    } else {
        return (
            <div className='bg-[#FFFFFF] rounded-[10px]  flex flex-col justify-center items-center w-[100%] md:h-[670px] h-[360px]'>
                <p className='border-b-[1px] border-[#EDF0F6]  md:mt-[27px] mt-[15px] md:text-[22px] text-[16px] font-bold text-Textdarkcolor w-[95%]'>Waiver of Liability</p>
                <div className='p-4 w-[90%] custom-scrollbar h-[100%]'>
                    <div >
                        <div className='flex py-3 items-center '>
                            <span className='w-0 h-[22px] px-[2px] rounded-[0px_8px_8px_0px] bg-[#30559C]'></span>
                            <p className='pl-2 text-[18px] font-semibold text-Textdarkcolor'>
                                Waiver of Liability
                            </p>
                        </div>
                        <p className='text-[#999B9F] lg:text-[16px] text-[12px]' dangerouslySetInnerHTML={{ __html: data.content ?? "" }}></p>
                    </div>
                </div>
            </div>

        );
    }
};

export default WaiverLiability;

