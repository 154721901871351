import React, { useState, useEffect } from 'react';
import Profile from '../Settings/Profile';
import ChangePassword from '../Settings/Changepass';
import Sidecard from '../Home/Sidecard';
import Settingsidecardq from './Settingsidecard';
import Settingsidecard from './Settingsidecard';
import Privacypolicy from './Privacypolicy';
import TermCondition from './Termcondition';
import Vendors from '../Home/Vendors';
import DefaultVendor from './DefaultVendor';
import DefaultVendorDetails from './DefaultVendorDetails';
import WaiverLiability from './WaiverLiability';

const Settingscard = () => {
    const [activeComponent, setActiveComponent] = useState('A');
    const renderComponent = () => {
        switch (activeComponent) {
            case 'A':
                return <Profile />;
            case 'B':
                return <ChangePassword />;
            case 'C':
                return <Privacypolicy />;
            case 'D':
                return <TermCondition />;
            case 'F':
                return <WaiverLiability />;
            case 'E':
                return <DefaultVendor />;
            case 'DefaultVendorDetails':
                return <DefaultVendorDetails />;
            default:
                return null;
        }
    };
    return (
        <div className="w-[100%] lg:py-8 pt-4 flex lg:flex-row flex-col justify-center items-center">
            <div className="   lg:w-[30%] w-[90%]  h-[100%]">
                <Settingsidecard activeComponent={activeComponent} setActiveComponent={setActiveComponent} />
            </div>
            <div className=" lg:w-[60%] w-[90%] lg:my-0 my-10 flex items-center justify-center flex-col">
                {renderComponent()}
            </div>
        </div>
    );
};

export default Settingscard;
















